import { useParams } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import { courseData } from "../../Objects/CourseTypes.js";
import { fetchData } from "../../Services/apiService.js";  // Import the service
import { Link } from 'react-router-dom'; // Import Link
let CourseInfo = () => {
    const { id } = useParams(); // Get the course ID from the URL
    const course = courseData.find((course) => course.id === parseInt(id, 10));
    const { lectID } = useParams(); // Get the course ID from the URL
    const [events, setEvents] = useState([]);
  
    // Define fetchEvents before useEffect
    
  
    useEffect(() => {
      const fetchEvents = async () => {
        if (course) {
            const url = `https://thakurcapital.com/Courses/API/ByID/Courses/${lectID}`;
            try {
                const eventData = await fetchData(url);
                setEvents(eventData);
            } catch (error) {
                console.error('Error fetching events:', error);
            }
        }
    };

    fetchEvents();
    
    }, [course, lectID]);


      if (!course) return <div>Loading...</div>;
    
  
  return (
    <section>
      <div className="jss8 css-j7qwjs">
        <div className="MuiContainer-root MuiContainer-maxWidthLg css-1qsxih2">
          <h5 className="MuiTypography-root MuiTypography-h5 jss14 css-zq6grw">
            {course.level}
          </h5>
          <h5 className="MuiTypography-root MuiTypography-h5 jss15 css-zq6grw">
            {course.title}
          </h5>
        </div>
      </div>

      <div className="jss17 MuiBox-root css-0"></div>
      <div className="container">
        <div className="css-1xhj18k">
          <div className="MuiContainer-root MuiContainer-maxWidthLg jss6 css-1qsxih2">
            <div className="css-j7qwjs">
              
            </div>
            <div >
              <div className="row">

              <div className="col-lg-12 col-sm-12">   
                 { events.map(event1 => (
                    <div>
                    <p><b>Course By :</b> { event1.CourseBy } </p>
                    
                    <p><b>Date :</b> { event1.CourseDate }</p>
                    <p><b>Location :</b> { event1.CourseLocation }</p>
                    <p><b>Other Details :</b>   { event1.Language } | { event1.CourseDesc } | { event1.Time }</p>
                    <p><b>Fees :</b>   ₹{ event1.Fees } </p>
                    <Link to={'/ApplyCourse/' + id + '/' + lectID} type="button" className="btn btn-primary">Apply Now</Link>
                    </div>
                       
                       

                 )) }
               
               <br></br>
               
              {console.log(events[0])}
              

             </div>

              <div className="col-lg-12 col-sm-12">


                <hr></hr>
              <h5 class="MuiTypography-root MuiTypography-h5 jss16 css-zq6grw">
                Seminar Highlights
              </h5>
              <hr></hr>
            
            { course.additionalDetails.topics.map(adldata => (
              <div> 
                     
                <h6 class="MuiTypography-root MuiTypography-h6 jss20 css-1anx036">
                 {adldata.title}
                </h6>
                
                <ul>
                {adldata.items.map((item, itemIndex) => (
                  <li key={itemIndex}>{item}</li>
                 ))}
                  
                </ul>
                </div>
               
              )) }
            
              </div>
              </div>
              <div className="row mt-4 pb-4 pt-2">
              <div className="col-lg-12 col-sm-12">
              
                        
                
             
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export { CourseInfo };
