import { useParams } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import { courseData } from "../../Objects/CourseTypes.js";
import { fetchData } from "../../Services/apiService.js";  // Import the service
import { Link } from 'react-router-dom'; // Import Link
let CourseDetails = () => {
    const { id } = useParams(); // Get the course ID from the URL
    const course = courseData.find((course) => course.id === parseInt(id, 10));
  
    const [events, setEvents] = useState([]);
    
    // Define fetchEvents before useEffect
    
  
    useEffect(() => {
      const fetchEvents = async () => {
        if (course) {
            const url = `https://thakurcapital.com/Courses/API/ByType/Courses/${course.level}`;
            try {
                const eventData = await fetchData(url);
                setEvents(eventData);
            } catch (error) {
                console.error('Error fetching events:', error);
            }
        }
    };

    fetchEvents();
    
    }, [course]);


      if (!course) return <div>Loading...</div>;
    
  
  return (
    <section>
      <div class="jss8 css-j7qwjs">
        <div class="MuiContainer-root MuiContainer-maxWidthLg css-1qsxih2">
          <h5 class="MuiTypography-root MuiTypography-h5 jss14 css-zq6grw">
            {course.level}
          </h5>
          <h5 class="MuiTypography-root MuiTypography-h5 jss15 css-zq6grw">
            {course.title}
          </h5>
        </div>
      </div>

      <div class="jss17 MuiBox-root css-0"></div>
      <div className="container">
        <div class="css-1xhj18k">
          <div class="MuiContainer-root MuiContainer-maxWidthLg jss6 css-1qsxih2">
          
            <div >
              <div className="row">
              <div className="col-lg-8 col-sm-12">
              <div class="css-j7qwjs">
            <h5 class="MuiTypography-root MuiTypography-h5 jss16 css-zq6grw">
            {course.description}
              </h5>
              <p> {course.detail}</p>
              <ul>
              { course.dtpoints.map(blpts => (
                <li>{blpts.bullet}</li>
              )) }
              </ul>

            <hr></hr>
              <h5 class="MuiTypography-root MuiTypography-h5 jss16 css-zq6grw">
                Seminar Highlights
              </h5>
              <hr></hr>
            </div>
            { course.additionalDetails.topics.map(adldata => (
              <div> 
                     
                <h6 class="MuiTypography-root MuiTypography-h6 jss20 css-1anx036">
                 {adldata.title}
                </h6>
                
                <ul>
                {adldata.items.map((item, itemIndex) => (
                  <li key={itemIndex}>{item}</li>
                 ))}
                  
                </ul>
                </div>
               
              )) }
              </div>

              <div className="col-lg-4 col-sm-12">
              
                <h6 class="MuiTypography-root MuiTypography-h6 jss20 css-1anx036">
                  Upcoming Seminar
                </h6>
                <div className=" row flex-row mt-4 pb-4 pt-2">
                { events.map(event => (
                    <div class="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation2 jss26 css-li77g6 col-sm=12">
                    <div class="css-u4p24i">
                      <svg
                        class="MuiSvgIcon-root MuiSvgIcon-colorPrimary MuiSvgIcon-fontSizeMedium jss11 css-jxtyyz"
                        focusable="false"
                        aria-hidden="true"
                        viewBox="0 0 24 24"
                        data-testid="EventAvailableRoundedIcon"
                      >
                        <path d="M16 10.53c-.29-.29-.77-.29-1.06 0l-4.35 4.35L9 13.29c-.29-.29-.77-.29-1.06 0-.29.29-.29.77 0 1.06l1.94 1.94c.39.39 1.02.39 1.41 0l4.7-4.7c.3-.29.3-.77.01-1.06zM19 3h-1V2c0-.55-.45-1-1-1s-1 .45-1 1v1H8V2c0-.55-.45-1-1-1s-1 .45-1 1v1H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-1 16H6c-.55 0-1-.45-1-1V8h14v10c0 .55-.45 1-1 1z"></path>
                      </svg>
                      <h5 class="MuiTypography-root MuiTypography-h5 jss27 css-zq6grw">
                      {event.CourseTitle}
                      </h5>
                    </div>
                    <div class="jss30 css-1vv03rk">
                      <svg
                        class="MuiSvgIcon-root MuiSvgIcon-colorPrimary MuiSvgIcon-fontSizeMedium jss11 css-jxtyyz"
                        focusable="false"
                        aria-hidden="true"
                        viewBox="0 0 24 24"
                        data-testid="DescriptionRoundedIcon"
                      >
                        <path d="M14.59 2.59c-.38-.38-.89-.59-1.42-.59H6c-1.1 0-2 .9-2 2v16c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8.83c0-.53-.21-1.04-.59-1.41l-4.82-4.83zM15 18H9c-.55 0-1-.45-1-1s.45-1 1-1h6c.55 0 1 .45 1 1s-.45 1-1 1zm0-4H9c-.55 0-1-.45-1-1s.45-1 1-1h6c.55 0 1 .45 1 1s-.45 1-1 1zm-2-6V3.5L18.5 9H14c-.55 0-1-.45-1-1z"></path>
                      </svg>
                      <h5 class="MuiTypography-root MuiTypography-h5 jss27 css-zq6grw">
                      {event.CourseSubTitle}
                      </h5>
                    </div>
                    <div class="css-1vv03rk">
                      <svg
                        class="MuiSvgIcon-root MuiSvgIcon-colorPrimary MuiSvgIcon-fontSizeMedium jss11 css-jxtyyz"
                        focusable="false"
                        aria-hidden="true"
                        viewBox="0 0 24 24"
                        data-testid="CalendarMonthRoundedIcon"
                      >
                        <path d="M17 2c-.55 0-1 .45-1 1v1H8V3c0-.55-.45-1-1-1s-1 .45-1 1v1H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2h-1V3c0-.55-.45-1-1-1zm2 18H5V10h14v10zm-8-7c0-.55.45-1 1-1s1 .45 1 1-.45 1-1 1-1-.45-1-1zm-4 0c0-.55.45-1 1-1s1 .45 1 1-.45 1-1 1-1-.45-1-1zm8 0c0-.55.45-1 1-1s1 .45 1 1-.45 1-1 1-1-.45-1-1zm-4 4c0-.55.45-1 1-1s1 .45 1 1-.45 1-1 1-1-.45-1-1zm-4 0c0-.55.45-1 1-1s1 .45 1 1-.45 1-1 1-1-.45-1-1zm8 0c0-.55.45-1 1-1s1 .45 1 1-.45 1-1 1-1-.45-1-1z"></path>
                      </svg>
                      <h5 class="MuiTypography-root MuiTypography-h5 jss27 css-zq6grw">
                      {event.CourseDate}
                      </h5>
                    </div>
                    <div class="css-1vv03rk">
                      <svg
                        class="MuiSvgIcon-root MuiSvgIcon-colorPrimary MuiSvgIcon-fontSizeMedium jss11 css-jxtyyz"
                        focusable="false"
                        aria-hidden="true"
                        viewBox="0 0 24 24"
                        data-testid="PersonRoundedIcon"
                      >
                        <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v1c0 .55.45 1 1 1h14c.55 0 1-.45 1-1v-1c0-2.66-5.33-4-8-4z"></path>
                      </svg>
                      <h5 class="MuiTypography-root MuiTypography-h5 jss27 css-zq6grw">
                      {event.CourseBy}
                      </h5>
                    </div>
                    <div class="css-1vv03rk">
                      <svg
                        class="MuiSvgIcon-root MuiSvgIcon-colorPrimary MuiSvgIcon-fontSizeMedium jss11 css-jxtyyz"
                        focusable="false"
                        aria-hidden="true"
                        viewBox="0 0 24 24"
                        data-testid="LanguageRoundedIcon"
                      >
                        <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm6.93 6h-2.95c-.32-1.25-.78-2.45-1.38-3.56 1.84.63 3.37 1.91 4.33 3.56zM12 4.04c.83 1.2 1.48 2.53 1.91 3.96h-3.82c.43-1.43 1.08-2.76 1.91-3.96zM4.26 14C4.1 13.36 4 12.69 4 12s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2s.06 1.34.14 2H4.26zm.82 2h2.95c.32 1.25.78 2.45 1.38 3.56-1.84-.63-3.37-1.9-4.33-3.56zm2.95-8H5.08c.96-1.66 2.49-2.93 4.33-3.56C8.81 5.55 8.35 6.75 8.03 8zM12 19.96c-.83-1.2-1.48-2.53-1.91-3.96h3.82c-.43 1.43-1.08 2.76-1.91 3.96zM14.34 14H9.66c-.09-.66-.16-1.32-.16-2s.07-1.35.16-2h4.68c.09.65.16 1.32.16 2s-.07 1.34-.16 2zm.25 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95c-.96 1.65-2.49 2.93-4.33 3.56zM16.36 14c.08-.66.14-1.32.14-2s-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2h-3.38z"></path>
                      </svg>
                      <h5 class="MuiTypography-root MuiTypography-h5 jss27 css-zq6grw">
                      {event.Language}
                      </h5>
                    </div>
                    <div class="css-1vv03rk">
                      <svg
                        class="MuiSvgIcon-root MuiSvgIcon-colorPrimary MuiSvgIcon-fontSizeMedium jss11 css-jxtyyz"
                        focusable="false"
                        aria-hidden="true"
                        viewBox="0 0 24 24"
                        data-testid="HourglassBottomRoundedIcon"
                      >
                        <path d="M16 22c1.1 0 2-.9 2-2l-.01-3.18c0-.53-.21-1.03-.58-1.41L14 12l3.41-3.43c.37-.37.58-.88.58-1.41L18 4c0-1.1-.9-2-2-2H8c-1.1 0-2 .9-2 2v3.16c0 .53.21 1.04.58 1.42L10 12l-3.41 3.4c-.38.38-.59.89-.59 1.42V20c0 1.1.9 2 2 2h8zM8 7.09V5c0-.55.45-1 1-1h6c.55 0 1 .45 1 1v2.09c0 .27-.11.52-.29.71L12 11.5 8.29 7.79c-.18-.18-.29-.44-.29-.7z"></path>
                      </svg>
                      <h5 class="MuiTypography-root MuiTypography-h5 jss27 css-zq6grw">
                      {event.Time}
                      </h5>
                    </div>
                    <div class="css-1vv03rk">
                      <svg
                        class="MuiSvgIcon-root MuiSvgIcon-colorPrimary MuiSvgIcon-fontSizeMedium jss11 css-jxtyyz"
                        focusable="false"
                        aria-hidden="true"
                        viewBox="0 0 24 24"
                        data-testid="LocationOnRoundedIcon"
                      >
                        <path d="M12 2c-4.2 0-8 3.22-8 8.2 0 3.18 2.45 6.92 7.34 11.23.38.33.95.33 1.33 0C17.55 17.12 20 13.38 20 10.2 20 5.22 16.2 2 12 2zm0 10c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"></path>
                      </svg>
                      <h5 class="MuiTypography-root MuiTypography-h5 jss27 css-zq6grw">
                      {event.CourseLocation}
                      </h5>
                    </div>
                    <div class="css-1vv03rk">
                      <svg
                        class="MuiSvgIcon-root MuiSvgIcon-colorPrimary MuiSvgIcon-fontSizeMedium jss11 css-jxtyyz"
                        focusable="false"
                        aria-hidden="true"
                        viewBox="0 0 24 24"
                        data-testid="CurrencyRupeeRoundedIcon"
                      >
                        <path d="M10.5 14h-.73l5.1 5.31c.61.64.16 1.69-.72 1.69-.27 0-.53-.11-.72-.31L7.4 14.41c-.26-.26-.4-.62-.4-.98 0-.79.64-1.43 1.43-1.43h2.07c1.76 0 3.22-1.3 3.46-3H7c-.55 0-1-.45-1-1s.45-1 1-1h6.66c-.56-1.18-1.76-2-3.16-2H7c-.55 0-1-.45-1-1s.45-1 1-1h10c.55 0 1 .45 1 1s-.45 1-1 1h-2.26c.48.58.84 1.26 1.05 2H17c.55 0 1 .45 1 1s-.45 1-1 1h-1.02c-.26 2.8-2.62 5-5.48 5z"></path>
                      </svg>
                      <h5 class="MuiTypography-root MuiTypography-h5 jss27 css-zq6grw">
                      {event.Fees}
                      </h5>
                    </div>
                    <div class="jss28 css-j7qwjs">
                      <h5 class="MuiTypography-root MuiTypography-h5 jss29 css-zq6grw">
                        Course Details :{" "}
                        <span class="jss31 MuiBox-root css-0">
                        {event.CourseDesc}
                        </span>
                      </h5>
                    </div>
                    
                    <Link to={'/CourseInfo/' + course.id + '/' + event.CourseID}
                      class="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-fullWidth MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-fullWidth css-1qelgoy"
                      tabindex="0"
                      type="button"
                    >
                      Register<span class="MuiTouchRipple-root css-w0pj6f"></span>
                      </Link>
                  </div>
                )) }
                </div>
                
                
             
              </div>
              </div>
              <div className="row mt-4 pb-4 pt-2">
              
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export { CourseDetails };
