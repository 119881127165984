import { FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram } from 'react-icons/fa';
import { Link } from 'react-router-dom'; // Import Link

const Footer = () => {
  return (
    <footer className="footer-section bg-dark text-white py-5">
      <div className="container">
        <div className="row">
          {/* About Us Section */}
          <div className="col-md-4 mb-4">
            <h5>About Thakur Capital</h5>
            <p>
              Thakur Capital offers comprehensive investment strategies and education to help you navigate the stock market with confidence. Our expert team is dedicated to your financial success.
            </p>
          </div>

          {/* Quick Links */}
          <div className="col-md-4 mb-4">
            <h5>Quick Links</h5>
            <ul className="list-unstyled">
              <li><Link to="/" className="text-white">Home</Link></li>
              <li><Link to="/courses"  href="#courses" className="text-white">Courses</Link></li>
              <li><Link to="/contact"  href="#contact" className="text-white">Contact Us</Link></li>
            </ul>
          </div>

          {/* Contact Info */}
          <div className="col-md-4 mb-4">
            <h5>Contact Us</h5>
            <p>
              <i className="fas fa-map-marker-alt"></i> Shop No. 116, Sharimal Miracle, Road No. 4, Next to Bank of India, Jawahar Nagar, Goregaon West, 400104
            </p>
            <p>
              <i className="fas fa-phone-alt"></i> +91 9867545497
            </p>
            <p>
              <i className="fas fa-envelope"></i> contact@thakurcapital.com
            </p>
          </div>
        </div>

        {/* Social Media Links */}
        <div className="text-center mt-4">
          <a href="https://facebook.com/thakurcapital" className="social-icon" aria-label="Facebook"><FaFacebookF /></a>
          <a href="https://twitter.com/thakurcapital" className="social-icon" aria-label="Twitter"><FaTwitter /></a>
          <a href="https://linkedin.com/company/thakurcapital" className="social-icon" aria-label="LinkedIn"><FaLinkedinIn /></a>
          <a href="https://instagram.com/thakurcapital" className="social-icon" aria-label="Instagram"><FaInstagram /></a>
        </div>

        {/* Copyright */}
        <div className="text-center mt-4">
          <p>&copy; {new Date().getFullYear()} Thakur Capital. All rights reserved.</p>
        </div>

        <div className="text-center mt-4">
          <p><Link to="/privacy-policy">Privacy Policy </Link> | <Link to="/terms-conditions"> Terms and Condition </Link>  </p>
        </div>
      </div>
    </footer>
  );
};

export {Footer};
