const courseData = [
  {
    id: 1,
    title: 'Trading & Investment Mindset',
    description: 'Start your day with insights into stock market trends, daily analysis, and actionable strategies to enhance your trading skills.',
    detail: 'Begin each day with a focused approach to stock trading. This course provides:',
    dtpoints: [
      { bullet: 'Daily market analysis and updates' },
      { bullet: 'Key insights on trend identification and predictions' },
      { bullet: 'Practical tips for successful day trading' }
    ],
    additionalDetails: {
      topics: [
        { title: 'Basics of Stock Market', items: [
          'How stock market works',
          'Why invest in stock market',
          'How to start investing',
          'Why Thakur Capital?'
        ]},
        { title: 'Candlestick Analysis', items: [
          'Psychology behind candlesticks',
          'Identify demand and supply zones'
        ]},
        { title: 'Sector Rotation', items: [
          'Identify which sector is likely to perform',
          'Focus on powerful stocks'
        ]},
        { title: 'Risk Management', items: [
          'Quantity to buy and stop-loss levels',
          'Risk-reward ratio',
          'Importance of risk management'
        ]},
        { title: 'Opportunity Identification', items: [
          'Find the best sectors',
          'Select the best stocks'
        ]},
        { title: 'Trading Psychology and Market Power', items: [
          'Unlock the power of stock market',
          'Understand trading psychology'
        ]}
      ]
    },
    image: 'https://thakurcapital.com/src/800x600-14.png',
    link: '/course/1',
    level: 'Level 0'
  },
  {
    id: 2,
    title: 'Stock Market Made Easy (SMME)',
    description: 'Understand the stock market fundamentals from scratch. Ideal for beginners, this course simplifies trading concepts and strategies.',
    detail: 'SMME demystifies the stock market for new investors. You’ll learn:',
    dtpoints: [
      { bullet: 'Essential trading and investment concepts' },
      { bullet: 'Market dynamics and stock valuation' },
      { bullet: 'Hands-on practice with trading tools and techniques' }
    ],
    additionalDetails: {
      topics: [
        { title: 'What We Get', items: [
          'Identify the best opportunities',
          'Path to success in stock market',
          'Understand sector rotation and performance',
          'Develop trading discipline'
        ]},
        { title: 'What We Learn', items: [
          'Technical analysis',
          'Money management',
          'Mind management',
          'Demand and supply zones',
          'Discipline in trading'
        ]},
        { title: 'Program Duration', items: [
          '8 lectures in the first month',
          '2-day full program',
          '8 follow-up lectures'
        ]}
      ]
    },
    image: 'https://thakurcapital.com/src/Stock-Market-for-Beginners-–-A-to-Z-of-Stock-Market.png',
    link: '/course/2',
    level: 'Level 1'
  },
  {
    id: 3,
    title: 'Thakur Capital Wealth Builder',
    description: 'Create long-term wealth through strategic stock market investment techniques, focusing on portfolio diversification and growth opportunities.',
    detail: 'Aimed at wealth creation, this course teaches:',
    dtpoints: [
      { bullet: 'Diversified investment strategies for long-term growth' },
      { bullet: 'Risk management and portfolio balancing' },
      { bullet: 'Methods for consistent returns in a volatile market' }
    ],
    additionalDetails: {
      topics: [
        { title: 'What We Get', items: [
          'Deep understanding of technical analysis',
          'In-depth trading psychology insights',
          'Decision-making in the market',
          'Wealth building through trading'
        ]},
        { title: 'What We Learn', items: [
          'How to analyze the market',
          'Selecting top-performing stocks',
          'Maximizing returns from the stock market'
        ]},
        { title: 'Program Duration', items: [
          '8 preparatory lectures',
          '2-day intensive program',
          '8 follow-up lectures'
        ]}
      ]
    },
    image: 'https://thakurcapital.com/src/book-money-management-on-table-260nw-644600752.png',
    link: '/course/3',
    level: 'Level 2'
  },
  {
    id: 4,
    title: 'Fear Less Trader (Mentorship)',
    description: 'Overcome trading fears with personalized mentorship, risk management strategies, and mindset training to achieve trading confidence.',
    detail: 'Develop trading confidence through mentorship. This program includes:',
    dtpoints: [
      { bullet: 'Personalized coaching to address individual trading fears' },
      { bullet: 'Advanced risk management strategies' },
      { bullet: 'Mindset development to enhance trading discipline' }
    ],
    additionalDetails: {
      topics: [
        { title: 'What We Get', items: [
          'Develop discipline in trading',
          'Become a professional stock market trader',
          'Understand the mindset of a trader',
          'Path to becoming a full-time trader'
        ]},
        { title: 'What We Learn', items: [
          'Daily routine of a live trader',
          'Mindset for full-time trading',
          'Understanding stock market movements',
          'Reading market signals'
        ]},
        { title: 'Program Duration', items: [
          '1-year comprehensive mentorship'
        ]}
      ]
    },
    image: 'https://thakurcapital.com/src/maxresdefault-1.jpg',
    link: '/course/4',
    level: 'Level 3'
  },
  {
    id: 5,
    title: 'Money Multiplication Program',
    description: 'Learn advanced techniques to maximize returns on investments. Ideal for experienced traders looking for exponential portfolio growth.',
    detail: 'Designed for ambitious traders, this program covers:',
    dtpoints: [
      { bullet: 'High-impact strategies for multiplying investments' },
      { bullet: 'Techniques for compounding and rapid growth' },
      { bullet: 'Portfolio optimization for sustainable profitability' }
    ],
    additionalDetails: {
      topics: [
        { title: 'What We Get', items: [
          'How to grow from 1 Lakh to 1 Crore in 5 years',
          'Best practices for wealth-building in the stock market',
          'Skill development for sustainable wealth creation',
          'Balancing trading and long-term investments'
        ]},
        { title: 'What We Learn', items: [
          'Trading psychology for effective decision-making',
          'Understanding money psychology',
          'Investment psychology to stay consistent'
        ]},
        { title: 'Program Duration', items: [
          '5-year structured program'
        ]}
      ]
    },
    image: 'https://thakurcapital.com/src/money_multiplication.png',
    link: '/course/5',
    level: 'Level 4'
  }
];

  export { courseData };