import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Header } from './Header';
import { Footer } from './Footer';
import { CourseDetails } from './Component/Courses/CourseDetails';
import { Home } from './Component/Home/Home';
import { CourseInfo } from './Component/Courses/CourseInfo';
import ScrollToTop from './ScrollToTop';
import ApplyCourse from './Component/Courses/ApplyCourse';
import VerifyRegistration from './Component/Courses/VerifyRegistration';
import { ContactUs } from './Component/Home/ContactUs';
import { CourseType } from './Component/Home/CourseTypes';
import { Termsandconditions } from './Component/Home/Termsandconditions';
import { PPolicy } from './Component/Home/PPolicy';

function App() {
  return (
    <Router>
      <ScrollToTop></ScrollToTop>
      <Header></Header>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/course/:id" element={<CourseDetails />} />
        <Route path="/CourseInfo/:id/:lectID" element={<CourseInfo />} />
        <Route path='/ApplyCourse/:id/:lectID'element={<ApplyCourse />} />
        <Route path="/verifyRegistration/:transactionID" element={<VerifyRegistration />} />
        <Route path="/contact" element={<ContactUs></ContactUs>} />
        <Route path="/courses" element={<CourseType></CourseType>} />
        <Route path="/terms-conditions" element={<Termsandconditions></Termsandconditions>} />
        <Route path="/privacy-policy" element={<PPolicy></PPolicy>} />
        
      </Routes>
      <Footer></Footer>

      </Router>
  );
}

export default App;
