// src/services/apiService.js

const username = 'onkarkumbhar42@gmail.com';
const password = 'start1234';

const headers = new Headers();
headers.set('Authorization', 'Basic ' + btoa(username + ":" + password));


export const fetchData = async (url) => {
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: headers
        });
        
        if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
        }
        
        const result = await response.json();
        return result.Data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};


export const postData = async (url, body) => {
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
        }

        const result = await response.json();
        return result.Data;
    } catch (error) {
        console.error('Error posting data:', error);
        throw error;
    }
};

