  const ContactUs = () => {
    return (
      <section  id="contact" className="contact-us-section py-5">
        <div className="container my-5">
      <h2 className="text-center course-title">Contact Us</h2>
      <p className="text-center">We are here to assist you with any inquiries about our stock market courses. Please feel free to reach out to us using the contact details below or visit our location.</p>
      <div className="row">
        <div className="col-lg-6">
        <div className="row justify-content-center">
        <div className="col-12 col-lg-12">
          <div className="embed-responsive embed-responsive-16by9">
          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3768.770492698152!2d72.84624!3d19.161521!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b64de5fc543f%3A0xf475b8ac492cc830!2sShalimar%20Miracle%2C%2026%2C%20Road%20No.%204%2C%20Jawahar%20Nagar%2C%20Goregaon%20West%2C%20Mumbai%2C%20Maharashtra%20400104%2C%20India!5e0!3m2!1sen!2sus!4v1730545223014!5m2!1sen!2sus" 
                  width="100%" 
                  height="450" 
                  className="embed-responsive-item"
                  style={{ border: 0 }}
                  allowfullscreen="" 
                  loading="lazy" 
                  referrerpolicy="no-referrer-when-downgrade"></iframe>

            
          </div>
        </div>
      </div>
        </div>
        <div className="col-lg-6">
        <div className="row text-center my-4">
        <div className="col-md-12">
          <p><i className="bi bi-telephone-fill"></i> <strong>Phone:</strong> +91 9867545497</p>
        </div>
        <div className="col-md-12">
          <p><i className="bi bi-envelope-fill"></i> <strong>Email:</strong> contact@thakurcapital.com</p>
        </div>
        <div className="col-md-12">
          <p><i className="bi bi-geo-alt-fill"></i> <strong>Address:</strong> Shop No. 116, Sharimal Miracle, Road No. 4, Next to Bank of India, Jawahar Nagar, Goregaon West, 400104</p>
        </div>
      </div>
        </div>
      </div>
     

     
    </div>
      </section>


    );
  };
  
  export {ContactUs};