import { Banner } from "./Banner"
import { Blogs } from "./Blogs"
import { ContactUs } from "./ContactUs"

import { CourseType } from "./CourseTypes"
import { Testimonials } from "./Testimonials"
import VideoSection from "./VedioSection"

let Home=()=>{
    return(
        <>
        <Banner></Banner>
        <CourseType></CourseType>
        <VideoSection></VideoSection>
        
        <Testimonials></Testimonials>
        <ContactUs></ContactUs>
        
        </>
    )
}
export{Home}