  const Termsandconditions = () => {
    return (
      <section  id="contact" className="contact-us-section py-5">
        <div className="container my-5">
      <h2 className="text-center mb-4">Terms & Conditions</h2>
      
      <p>
        The website <a href="https://thakurcapital.com" target="_blank" rel="noopener noreferrer">https://thakurcapital.com</a> is owned and operated by Thakur Capital. Before you proceed further with your decision to avail any of our services, kindly take a moment to read all the Terms and Conditions.
      </p>

      <section className="mb-4">
        <h4>General Terms</h4>
        <p>
          These Terms and Conditions apply to all visitors, users, and others who access or use our services. By accessing or using our services, you agree to be bound by these Terms and Conditions. If you disagree with any part of the terms, you may not access the services.
        </p>
        <p>
          Thakur Capital is an educational institution solely focused on providing financial education, particularly in the field of stock market trading. All the information on this website is for general informational purposes only. While we strive to keep the information up-to-date and accurate, we make no representation or warranty of any kind, express or implied, regarding the completeness, accuracy, reliability, or suitability of the website’s information, products, services, or related graphics. Any reliance on such information is strictly at your own risk.
        </p>
        <p>
          If you are under the age of 18 and over 13, you may use the site under the supervision of a parent or legal guardian who agrees to these terms. Thakur Capital reserves the right to deny admission to any of its courses or seminars without justification.
        </p>
      </section>

      <section className="mb-4">
        <h4>Copyrights and Trademarks</h4>
        <p>
          Unless otherwise stated, all the copyrights to the intellectual property shared on the website, including but not limited to audio, video, text, and graphical data, are the sole property of Thakur Capital, protected under Indian law. Any infringement or attempts to infringe on these rights will be punishable by law.
        </p>
      </section>

      <section className="mb-4">
        <h4>Disclaimer</h4>
        <p>
          The information on <a href="https://thakurcapital.com" target="_blank" rel="noopener noreferrer">https://thakurcapital.com</a> is for general information only and is not an offer to buy or sell any security. It does not constitute a personal recommendation or consider individual investment objectives, financial situations, or needs. Any forward-looking statements are subject to change without notice. Prospective traders should do their own research or consult with an independent financial adviser before acting on any of the information provided.
        </p>
        <p>
          Thakur Capital is not responsible for the outcomes of any trading or investment decisions made based on the content of this website. Testimonials reflect personal experiences and are not indicative of typical results for all clients. We do not guarantee profits or provide any advisory services.
        </p>
      </section>

      <section className="mb-4">
        <h4>Social Media Disclaimer</h4>
        <p>
          Thakur Capital does not warrant the accuracy, completeness, or usefulness of the information available on its social media pages, including Facebook and YouTube. Nothing provided through these platforms constitutes financial advice or solicitation for any financial product or service.
        </p>
      </section>

      <section className="mb-4">
        <h4>Privacy Policy</h4>
        <p>
          We collect personal information during registration, course purchases, and account access for the purpose of identifying and serving users. Your personal information will be kept confidential and will not be sold or rented to third parties. In case of the need to share your information, we will obtain your consent. We may use third-party services such as Google Analytics to track website traffic and analyze the use of our service.
        </p>
        <p>
          Thakur Capital reserves the right to modify its privacy policy from time to time, and any significant changes will be posted on this site.
        </p>
      </section>

      <section className="mb-4">
        <h4>User Obligations</h4>
        <ul>
          <li>Your login information is for personal use only and should not be shared.</li>
          <li>You will not copy, reproduce, or distribute any course materials, seminars, or videos without our permission.</li>
          <li>Unauthorized reproduction or distribution of materials may lead to legal action.</li>
          <li>Thakur Capital is not responsible for your trading results. We do not offer buy/sell tips or advisory services.</li>
          <li>You will not use our website or social media for advertising or personal solicitations.</li>
          <li>You will not use automated systems (e.g., robots, spiders) to access or monitor the website without permission.</li>
          <li>You are responsible for any content you post on our website or social media forums and agree to avoid offensive or inappropriate material.</li>
        </ul>
      </section>

      <section className="mb-4">
        <h4>Governing Law</h4>
        <p>
          These Terms and Conditions shall be governed by and construed in accordance with the laws of Pune, India. If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions will remain in effect. These Terms constitute the entire agreement between us concerning our services.
        </p>
      </section>

      <section className="mb-4">
        <h4>Refund Policy</h4>
        <p>
          Thakur Capital offers a 100% refund, no questions asked, within the first 15 days of the course start date. After the 15-day period, fees are non-transferable and non-refundable.
        </p>
        <p>
          In rare cases, exceptions may be considered at the discretion of Thakur Capital management. All refund requests must be kept confidential between the beneficiary and Thakur Capital.
        </p>
      </section>
      </div>
      </section>


    );
  };
  
  export {Termsandconditions};