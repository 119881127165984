import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { postData } from '../../Services/apiService.js';  // Import the postData function
import { courseData } from "../../Objects/CourseTypes.js";

const ApplyCourse = () => {

    const { id } = useParams(); // Get the course ID from the URL
    const course = courseData.find((course) => course.id === parseInt(id, 10));
    const { lectID } = useParams(); // Get the course ID from the URL

    const generateGUID = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            const r = (Math.random() * 16) | 0;
            const v = c === 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    };
    let trid = generateGUID() ;
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        Fname: '',
        Mname: '',
        Lname: '',
        CourceID: lectID,
        ContactNo: '',
        AlternateNo: '',
        State: '',
        City: '',
        Pincode: '',
        Address: '',
        PaymentMode: 'Online', // default value
        PaymentStatus: 'Not paid', // default value
        Fees: '',
        EmailID: '',
        TransactionID: trid 
    });

    
    

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const body = {
            Data: [
                {
                    RegID: "New",
                    ...formData,
                    RegisteredOn: new Date().toISOString(), // Set current date
                }
            ]
        };

        try {
            const result = await postData('https://thakurcapital.com/Courses/API/Update/Registrations', body);
            //alert('Registration successful');
            navigate(`/verifyRegistration/${formData.TransactionID}`);
            console.log(result);
        } catch (error) {
            alert('Error submitting registration');
        }
    };

    return (
        <div className="my-appl-box">
        <div className="container mt-5">
            <h2>Apply for Course</h2>
            <form onSubmit={handleSubmit}>
                <div className="row mb-3">
                    <div className="col-md-4">
                        <label htmlFor="Fname" className="form-label">First Name</label>
                        <input
                            type="text"
                            className="form-control"
                            id="Fname"
                            name="Fname"
                            value={formData.Fname}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="Mname" className="form-label">Middle Name</label>
                        <input
                            type="text"
                            className="form-control"
                            id="Mname"
                            name="Mname"
                            value={formData.Mname}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="Lname" className="form-label">Last Name</label>
                        <input
                            type="text"
                            className="form-control"
                            id="Lname"
                            name="Lname"
                            value={formData.Lname}
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-md-6">
                        <label htmlFor="ContactNo" className="form-label">Contact No</label>
                        <input
                            type="text"
                            className="form-control"
                            id="ContactNo"
                            name="ContactNo"
                            value={formData.ContactNo}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="AlternateNo" className="form-label">Alternate No</label>
                        <input
                            type="text"
                            className="form-control"
                            id="AlternateNo"
                            name="AlternateNo"
                            value={formData.AlternateNo}
                            onChange={handleChange}
                        />
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-md-4">
                        <label htmlFor="State" className="form-label">State</label>
                        <input
                            type="text"
                            className="form-control"
                            id="State"
                            name="State"
                            value={formData.State}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="City" className="form-label">City</label>
                        <input
                            type="text"
                            className="form-control"
                            id="City"
                            name="City"
                            value={formData.City}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="Pincode" className="form-label">Pincode</label>
                        <input
                            type="text"
                            className="form-control"
                            id="Pincode"
                            name="Pincode"
                            value={formData.Pincode}
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-md-12">
                        <label htmlFor="Address" className="form-label">Address</label>
                        <input
                            type="text"
                            className="form-control"
                            id="Address"
                            name="Address"
                            value={formData.Address}
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-md-6">
                        <label htmlFor="EmailID" className="form-label">Email</label>
                        <input
                            type="email"
                            className="form-control"
                            id="EmailID"
                            name="EmailID"
                            value={formData.EmailID}
                            onChange={handleChange}
                            required
                        />
                    </div>
                   
                </div>

                <div className="row mb-3">
                   
                </div>

                <button type="submit" className="btn btn-primary">Submit</button>
                <br></br>
                <br></br>
                <br></br>
            </form>
        </div>
        </div>
    );
};

export default ApplyCourse;
