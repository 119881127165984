import { Link } from 'react-router-dom'; // Import Link
import { courseData } from '../../Objects/CourseTypes';


let CourseType = () =>{
    return(
       
        <section class="course-section">
  <h2 class="course-title">Our Programs</h2>
        <div className="container my-5">
      <div className="row">
        {courseData.map((course) => (
          <div className="col-md-6 col-lg-4 mb-4" key={course.id}>
            <div className="card h-100">
              <img src={course.image} className="card-img-top" alt={course.title} />
              <div className="card-body">
                <h5 className="card-title">{course.title}</h5>
                <p className="card-text">{course.description}</p>
                <Link to={course.link} className="btn btn-primary">Know More</Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
    </section>

    );
}

export{CourseType};