import logo from './Img/ThakurCapital.png';
import { Link, useLocation } from 'react-router-dom'; // Import Link


 let Header = () => {

    const location = useLocation();
  const isActiveCourse = location.pathname.startsWith('/course');

    return(
         <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-top">
      <div className="container-fluid">
        <a className="navbar-brand" href="/"> <img
              alt=""
              src={logo}
              width="140"
              
              className="d-inline-block align-top"
            />{' '}
           </a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <Link to="/" className="nav-link" >Home</Link>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#about">About</a>
            </li>
            <li className="nav-item dropdown">
              <a className= {`nav-link dropdown-toggle ${isActiveCourse ? 'nav-highlight' : ''}`} href="#" id="coursesDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Our Programs
              </a>
              <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="coursesDropdown">
              <li>
                  <Link className="dropdown-item" to="/course/1">Trading & Investment Mind set</Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/course/2">Stock Market Made Easy (SMME)</Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/course/3">Thakur Capital Wealth Builder</Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/course/4">Fear Less Trader (Mentorship)</Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/course/5">Money Multiplication Program</Link>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <Link to="/contact" className="nav-link" href="#contact">Contact</Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    )
 }

 export {Header};