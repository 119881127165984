import React, { useEffect, useRef } from 'react';
import './VideoSection.css';

const VideoSection = () => {
  const trackRef = useRef(null);
  let scrollAmount = 0;

  const videos = [
    "https://www.youtube.com/embed/RuynpV90wzY",
    "https://www.youtube.com/embed/UqdgAJbFvN8",
    "https://www.youtube.com/embed/ZTZVuMLsCLw",
    "https://www.youtube.com/embed/u6dRbyvILns",
    "https://www.youtube.com/embed/F35zjF-gwm4",
    "https://www.youtube.com/embed/VnuX72oiVVQ",
    "https://www.youtube.com/embed/iBlZDnME-nY",
    "https://www.youtube.com/embed/7nLT7rSFeUE",
    "https://www.youtube.com/embed/FzTrklerlbE"
  ];

  useEffect(() => {
    const track = trackRef.current;

   // const autoScroll = () => {
   //   scrollAmount += 300;
   //   if (scrollAmount >= track.scrollWidth - track.clientWidth) {
   //     scrollAmount = 0;
   //   }
   //   track.style.transform = `translateX(-${scrollAmount}px)`;
   // };

   // const interval = setInterval(autoScroll, 3000);
   // return () => clearInterval(interval);
  }, []);

  const handleRightClick = () => {
    scrollAmount += 300;
    if (scrollAmount >= trackRef.current.scrollWidth - trackRef.current.clientWidth) {
      scrollAmount = 0;
    }
    trackRef.current.style.transform = `translateX(-${scrollAmount}px)`;
  };

  const handleLeftClick = () => {
    scrollAmount -= 300;
    if (scrollAmount < 0) {
      scrollAmount = trackRef.current.scrollWidth - trackRef.current.clientWidth;
    }
    trackRef.current.style.transform = `translateX(-${scrollAmount}px)`;
  };

  return (
    <section className="video-section">
      <div className="container">
        <h2 className="text-center course-title">Popular Videos</h2>
        <div className="video-carousel">
          <div className="video-track" ref={trackRef}>
            {videos.map((video, index) => (
              <div className="video-item" key={index}>
                <iframe
                  src={video}
                  frameBorder="0"
                  allowFullScreen
                  title={`video-${index}`}
                ></iframe>
              </div>
            ))}
          </div>
          <button className="scroll-btn left-btn" onClick={handleLeftClick}>
            &#10094;
          </button>
          <button className="scroll-btn right-btn" onClick={handleRightClick}>
            &#10095;
          </button>
        </div>
      </div>
    </section>
  );
};

export default VideoSection;
