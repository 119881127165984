import '../../App.css';
import React, { useEffect, useState } from 'react';
import { fetchData } from "../../Services/apiService.js";  // Import the service
import { Link } from 'react-router-dom'; // Import Link

let Banner = ()=>{

  const [events, setEvents] = useState([]);
  
  // Define fetchEvents before useEffect
  

  useEffect(() => {
    const fetchEvents = async () => {
     
          const url = `https://thakurcapital.com/Courses/API/ByType/Courses/Level 0`;
          try {
              const eventData = await fetchData(url);
              setEvents(eventData);
          } catch (error) {
              console.error('Error fetching events:', error);
          }
      
  };

  fetchEvents();
  
  }, []);


    return(
        <div className="banner-section text-white">
      <div className="overlay">
        <div className="container d-flex flex-column justify-content-center align-items-start h-100">
          <h1 className="display-4 mb-4">Welcome to Thakur Capital</h1>
          <ul className="course-list">
            {events.slice(0, 5).map((course) => (
              <li key={course.CourseID}>
                <h5>{course.CourseTitle}</h5>
                <div className="row">

                <div className="col-lg-4 col-md-4 col-12 " >
                <p>{course.CourseDesc}</p>
                <small>{course.CourseDate} - {course.Language}</small>
                </div>

                <div className="col-lg-4 col-lg-4 col-12">
                <p>{course.CourseBy}</p>
                <small>{course.Time} </small>
                </div>

                <div className="col-lg-4 col-lg-4 col-12">
                <Link to ={"CourseInfo/1/"+course.CourseID} className="btn btn-warning btn-sm">Register</Link>
                </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
    )
}

export {Banner};