const testimonials = [
    {
      text: "The trading system I learnt here is awesome Sir. I am thankful to you for wonderful hand holding,and yes your teaching is making me a money making machine A big Thankyou!",
      author: "Vivek Kulkarni",
    },
    {
      text: "The courses offered are very insightful and practical. I highly recommend Thakur Capital for anyone looking to succeed in the stock market.",
      author: "Onkar Kumbhar",
    },
    {
      text: "Excellent support and resources. The guidance provided has been instrumental in my trading journey.",
      author: "Nitin",
    },
    // Add more testimonials as needed
  ];
  
  const Testimonials = () => {
    return (
      <section className="testimonials-section py-5">
        <div className="container">
          <h2 className="text-center course-title">What Our Clients Say</h2>
          <div className="row">
            {testimonials.map((testimonial, index) => (
              <div className="col-md-4" key={index}>
                <div className="card testimonial-card">
                  <div className="card-body">
                    <div className="testimonial-content">
                      <p className="card-text">"{testimonial.text}"</p>
                      <p className="card-author">- {testimonial.author}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>


    );
  };
  
  export {Testimonials};