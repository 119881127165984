import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
  
  const PPolicy = () => {
    return (
      <Container className="my-5">
      <Row>
        <Col>
          <h2 className="mb-4">Privacy Policy</h2>
          <p><strong>Effective date:</strong> December 13, 2023</p>
          <p>
            Thakur Capital (“us”, “we”, or “our”) operates the website and related services (the “Service”). This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data. We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.
          </p>

          <h4 className="mt-4">Information Collection And Use</h4>
          <p>
            We collect several different types of information for various purposes to provide and improve our Service to you.
          </p>

          <h5 className="mt-3">Types of Data Collected</h5>
          
          <h6>Personal Data</h6>
          <p>
            While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (“Personal Data”). Personally identifiable information may include, but is not limited to:
          </p>
          <ul>
            <li>Email address</li>
            <li>First name and last name</li>
            <li>Phone number</li>
            <li>Address, State, Province, ZIP/Postal code, City</li>
            <li>Cookies and Usage Data</li>
          </ul>

          <h6>Usage Data</h6>
          <p>
            We may also collect information that your browser sends whenever you visit our Service or when you access the Service by or through a mobile device (“Usage Data”). This Usage Data may include information such as your computer’s Internet Protocol address, browser type, the pages of our Service that you visit, and other diagnostic data.
          </p>

          <h4 className="mt-4">Tracking & Cookies Data</h4>
          <p>
            We use cookies and similar tracking technologies to track the activity on our Service and hold certain information.
          </p>

          <h4 className="mt-4">Use of Data</h4>
          <p>Thakur Capital uses the collected data for various purposes:</p>
          <ul>
            <li>To provide and maintain the Service</li>
            <li>To notify you about changes to our Service</li>
            <li>To provide customer care and support</li>
            <li>To detect, prevent, and address technical issues</li>
          </ul>

          <h4 className="mt-4">Transfer Of Data</h4>
          <p>
            Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your jurisdiction.
          </p>

          <h4 className="mt-4">Disclosure Of Data</h4>
          <h5>Legal Requirements</h5>
          <p>
            Thakur Capital may disclose your Personal Data in the good faith belief that such action is necessary to comply with a legal obligation.
          </p>

          <h4 className="mt-4">Security Of Data</h4>
          <p>
            The security of your data is important to us, but remember that no method of transmission over the Internet is 100% secure.
          </p>

          <h4 className="mt-4">Service Providers</h4>
          <p>We may employ third-party companies and individuals to facilitate our Service.</p>

          <h5 className="mt-3">Analytics</h5>
          <p>
            We may use third-party Service Providers to monitor and analyze the use of our Service, like Google Analytics.
          </p>

          <h4 className="mt-4">Links To Other Sites</h4>
          <p>
            Our Service may contain links to other sites that are not operated by us. We strongly advise you to review the Privacy Policy of every site you visit.
          </p>

          <h4 className="mt-4">Children’s Privacy</h4>
          <p>
            Our Service does not address anyone under the age of 18. We do not knowingly collect personally identifiable information from anyone under 18.
          </p>

          <h4 className="mt-4">Changes To This Privacy Policy</h4>
          <p>
            We may update our Privacy Policy from time to time. You are advised to review this Privacy Policy periodically for any changes.
          </p>

          <h4 className="mt-4">Contact Us</h4>
          <p>
            If you have any questions about this Privacy Policy, please contact us:
          </p>
          <ul>
            <li>By email: <a href="mailto:contact@thakurcapital.com">contact@thakurcapital.com</a></li>
          </ul>
        </Col>
      </Row>
    </Container>


    );
  };
  
  export {PPolicy};