import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { postData } from '../../Services/apiService.js';  // Import the postData function
import { useNavigate } from 'react-router-dom';

const VerifyRegistration = () => {
    const { transactionID } = useParams(); // Get the Transaction ID from URL
    const [email, setEmail] = useState('okk420.ok@gmail.com'); // Example email, replace with actual data
    const [otp, setOtp] = useState('');
    const [isPaymentVerified, setIsPaymentVerified] = useState(false);
    const [receiptData, setReceiptData] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {

        const handleSubmitOtp = async (e) => {
        
            // Logic to send OTP
           // e.preventDefault();
            
            const body = "";
    
            try {
                  
                initiatePayment();
    
            } catch (error) {

            }
    
        };


        handleSubmitOtp(); // Call the function to send OTP
    }, [transactionID, email]); // Dependencies for the useEffect


    

    const initiatePayment = async () => {
        const body = "";
        try {
            // Call your backend API to create Razorpay order
            const data = await postData('https://thakurcapital.com/Courses/API/createorder/'+transactionID, body);
         console.log(data[0]);
            const { id: order_id, amount, currency, Status } = data[0];
            console.log(Status);
            if (Status === "Already Paid") {
                navigate(`/`);
                console.log("abcd efg hi jkl");
            }
            else if (Status === "Free") {
                console.log(data[0]);
                setReceiptData(data[0]);
                   setIsPaymentVerified(true);
            
            } else {

            // Options for Razorpay payment
            const options = {
                key: "rzp_live_1KBN81V4vtMQBt",
                amount: amount,
                currency: currency,
                order_id: order_id,
                name: "Thakur Capital",
                description: "Purchase Description",
                handler: function (response) {
                    const { razorpay_payment_id, razorpay_order_id, razorpay_signature } = response;
                    
                    verifypayment(razorpay_payment_id, razorpay_order_id, razorpay_signature);



                    // Handle success
                    alert("Payment successful: " + response.razorpay_payment_id );
                    // Optionally, save the payment ID to your server for reference
                },
                prefill: {
                    name: "Customer Name",
                    email: "customer@example.com",
                    contact: "9999999999"
                }
            };

            const razorpay = new window.Razorpay(options);
            razorpay.open(); // Open Razorpay Checkout
        }
        } catch (error) {
            console.error("Failed to initiate payment:", error);
        }
    };

    const verifypayment = async (razorpay_payment_id, razorpay_order_id, razorpay_signature) => {
        const body = "";
        try {
            // Call your backend API to create Razorpay order
            const recipt = await postData('https://thakurcapital.com/Courses/API/verifypayment/'+razorpay_payment_id+'/'+razorpay_order_id+'/'+razorpay_signature+'/'+transactionID, body);
         console.log(recipt[0]);
         setReceiptData(recipt[0]);
            setIsPaymentVerified(true);
           
        } catch (error) {
            console.error("Payment verification faild:", error);
        }

    };

    return (
        <div className="container mt-5">
             {isPaymentVerified && receiptData && (
                <div className="container">
                <h2>Payment Receipt</h2>
                <h3>Course Details</h3>
                <ul>
                    <li><strong>Course ID:</strong> {receiptData.CourseID}</li>
                    <li><strong>Title:</strong> {receiptData.CourseTitle}</li>
                    <li><strong>Subtitle:</strong> {receiptData.CourseSubTitle}</li>
                    <li><strong>Type:</strong> {receiptData.CourseType}</li>
                    <li><strong>Description:</strong> {receiptData.CourseDesc}</li>
                    <li><strong>Date:</strong> {receiptData.CourseDate}</li>
                    <li><strong>Location:</strong> {receiptData.CourseLocation}</li>
                    <li><strong>Instructor:</strong> {receiptData.CourseBy}</li>
                    <li><strong>Language:</strong> {receiptData.Language}</li>
                    <li><strong>Fees:</strong> {receiptData.Fees || 'N/A'}</li>
                    <li><strong>Time:</strong> {receiptData.Time}</li>
                </ul>
                <h3>Participant Information</h3>
                <ul>
                    <li><strong>First Name:</strong> {receiptData.Fname}</li>
                    <li><strong>Middle Name:</strong> {receiptData.Mname}</li>
                    <li><strong>Last Name:</strong> {receiptData.Lname}</li>
                    <li><strong>Contact No:</strong> {receiptData.ContactNo}</li>
                    <li><strong>Email ID:</strong> {receiptData.EmailID}</li>
                </ul>
                <h3>Payment Status</h3>
                <p>{receiptData.PaymentStatus}</p>
            </div>

            )}
        </div>
    );
};

export default VerifyRegistration;
